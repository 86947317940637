
















import { defineComponent, ref, watch } from "@/composition-api";
import UiPageLayout from "@/components/ui/UiPageLayout.vue";
import UiFormattedContent from "@/components/ui/UiFormattedContent/UiFormattedContent.vue";
import useFetchStaticPage from "@/composition/useFetchStaticPage";

export default defineComponent({
  name: "Legal",
  props: {
    title: {
      type: String,
      default: "Impressum"
    },
    source: {
      type: String,
      default: "Imprint"
    }
  },
  components: {
    UiFormattedContent,
    UiPageLayout
  },
  setup(props) {
    const content = ref(null);

    const fetchImprint = async () => {
      content.value = await useFetchStaticPage(props.source);
    };

    fetchImprint();

    watch(() => props.source, fetchImprint);

    return { content };
  }
});
